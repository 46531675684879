<template>
  <div>
    <b-card class="scatter-three-card custom-card">
      <b-row class="flex-nowrap d-flex align-items-center">
        <b-col cols="auto">
          <b-form-group class="mb-0" style="min-width: 300px">
            <ScatterSelect v-model="datasourceSelected" />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <option-chart :label="$t('option_chart')" :showScale="true" :options="[]" :typeList="[]" :nameComponent="$options.name" />
        </b-col>
        <b-col cols="auto" class="ml-auto d-flex align-items-center">
          <SummaryData :chartData="chartData" />
          <Downloader :downloadImage="downloadImage" :downloadHtml="downloadHtml" />
        </b-col>
      </b-row>
    </b-card>

    <ScatterChart ref="chart" :nameComponent="`scatter_three`" />

    <Timeplayer ref="timeplayer" v-model="date" :liveMode="liveMode" :speedMax="12" :speedValue="speed" @speedInput="speed = $event" :moveToEnd="moveToEnd" />
  </div>
</template>

<script>
const _ = require('lodash')
import * as d3 from 'd3'
import gsap from 'gsap'
import ScatterSelect from './ScatterSelectComponent.vue'
import CheckboxSingle from '../../common/CheckboxSingle.vue'
import Downloader from '../../common/Downloader.vue'
import PausePlayerMixin from '@/mixins/PausePlayerMixin'
import RadioGroup from '../../common/RadioGroup.vue'
import SummaryData from '../../common/SummaryData.vue'
import ChartYearMixin from '@/mixins/ChartYearMixin'
import ScatterChart from './ScatterChartComponent.vue'
import Timeplayer from '../../common/Timeplayer.vue'
import { ThemeConfig } from '@/mixins/ThemeMixin'
import { OPTION_PLOTLY_PALETTE } from '@/constants/colors'
import OptionChart from '../../common/OptionChartPlotly.vue'
import html2canvas from 'html2canvas'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import FileSaver from 'file-saver'

export default {
  name: 'scatter_three',
  components: { ScatterSelect, Downloader, SummaryData, RadioGroup, ScatterChart, Timeplayer, CheckboxSingle, OptionChart, ToastificationContent },
  mixins: [PausePlayerMixin, ChartYearMixin, ThemeConfig],
  async mounted() {
    let layout = await this.getLayout()
    let data = await this.getData()
    let date = this.animateDateArray
    this.$refs.chart.createChart(data, layout, date)
  },
  data() {
    return {
      speed: 6,
      datasourceExtended: [],
      dateArray: [],
      animateDateArray: [],
      chartData: [],
      isDowload: false,
      moveToEnd: 0,
      colorSelect: null,
    }
  },
  computed: {
    background() {
      let skin = this.$store.state.appConfig.layout.skin
      return skin === 'dark' ? '#283046' : '#ffffff'
    },
    scale() {
      return this.$store.state.tabs[this.$options.name].scale
    },
    datasourceSelected: {
      get() {
        return this.$store.state.tabs[this.$options.name].datasourceSelected
      },
      set(datasourceSelected) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { datasourceSelected })
      },
    },
    date: {
      get() {
        return this.$store.state.tabs[this.$options.name].date
      },
      set(date) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { date })
      },
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject
    },
    markerColor() {
      let colors = this.$store.state.tabs[this.$options.name].colors
      colors = colors && colors.length > 0 ? colors : OPTION_PLOTLY_PALETTE.D3
      this.colorSelect = colors
      return colors
    },
    sizeWidth() {
      return this.$store.state.tabs[this.$options.name].sizeWidth
    },
    liveMode() {
      try {
        let check = false
        if (!this.datasources || !this.datasourceSelected) return false
        let arryIdDatasources = []
        this.datasources.map((data) => {
          arryIdDatasources.push(data.id)
        })
        arryIdDatasources = _.unionBy(arryIdDatasources)
        this.datasourceSelected.map((data) => {
          let splitX = data.x ? data.x.split(' : ') : null
          const idX = splitX ? splitX[0] : null
          let splitY = data.y ? data.y.split(' : ') : null
          const idY = splitY ? splitY[0] : null
          let splitZ = data.z ? data.z.split(' : ') : null
          const idZ = splitZ ? splitZ[0] : null
          if (idX && idY && idZ && data.value) {
            let indexDataX = arryIdDatasources.indexOf(idX)
            let indexDataY = arryIdDatasources.indexOf(idY)
            let indexDataZ = arryIdDatasources.indexOf(idZ)
            if ((indexDataX > -1 && this.datasources[indexDataX].live) || (indexDataY > -1 && this.datasources[indexDataY].live) || (indexDataZ > -1 && this.datasources[indexDataZ].live)) {
              check = true
              return check
            }
          }
        })
        return check
      } catch {}
      return false
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode
    },
  },
  watch: {
    datasourceSelected() {
      // set datasourceExtended
      let datasourceExtended = []

      this.datasourceSelected.forEach((datasourceSelected) => {
        if (!datasourceSelected.x || !datasourceSelected.y || !datasourceSelected.z || !datasourceSelected.value) return

        let [dX, iX] = datasourceSelected.x.split(' : ')
        let [dY, iY] = datasourceSelected.y.split(' : ')
        let [dZ, iZ] = datasourceSelected.z.split(' : ')
        let [dV, iV] = datasourceSelected.value.split(' : ')

        if (!this.$db[dX] || !this.$db[dY] || !this.$db[dZ] || !this.$db[dV]) return
        if (dX === dY && dY === dZ && dZ === dV) {
          // prettier-ignore
          let locationsX = Object.keys(this.$db[dX].columns).filter(pair => pair.endsWith(`*${iX}`)).map(pair => pair.split('*')[0])
          // prettier-ignore
          let locationsY = Object.keys(this.$db[dY].columns).filter(pair => pair.endsWith(`*${iY}`)).map(pair => pair.split('*')[0])
          // prettier-ignore
          let locationsZ = Object.keys(this.$db[dZ].columns).filter(pair => pair.endsWith(`*${iZ}`)).map(pair => pair.split('*')[0])
          // prettier-ignore
          let locationsV = Object.keys(this.$db[dV].columns).filter(pair => pair.endsWith(`*${iV}`)).map(pair => pair.split('*')[0])

          let locationsIntersect = _.intersection(locationsX, locationsY, locationsZ, locationsV)

          datasourceExtended.push({ dX, iX, dY, iY, dZ, iZ, dV, iV, locations: locationsIntersect })
        } else {
          this.$toast({ component: ToastificationContent, props: { title: this.$t('warning'), icon: 'BellIcon', text: this.$i18n.t('no_data_match'), variant: 'warning ' } })
        }
      })

      this.datasourceExtended = datasourceExtended
      this.moveToEnd++
    },
    async datasourceExtended() {
      let layout = await this.getLayout()
      let data = await this.getData()
      let date = this.animateDateArray
      this.$refs.chart.updateChart(data, layout, date)
    },
    async scale() {
      let layout = await this.getLayout()
      let data = await this.getData()
      let date = this.animateDateArray
      this.$refs.chart.updateChart(data, layout, date)
    },
    async markerColor() {
      let layout = await this.getLayout()
      let data = await this.getData()
      let date = this.animateDateArray
      this.$refs.chart.updateChart(data, layout, date)
    },
    async sizeWidth() {
      let layout = await this.getLayout()
      let data = await this.getData()
      let date = this.animateDateArray
      this.$refs.chart.updateChart(data, layout, date)
    },
    date() {
      if (this.date.length === 1) {
        this.dateArray = this.$refs.timeplayer.dates.reduce((res, cur) => {
          if (cur === this.date[0]) res.push(new Date(cur).getTime())
          return res
        }, [])
      } else if (this.date.length === 2) {
        this.dateArray = this.$refs.timeplayer.dates.reduce((res, cur) => {
          if (cur >= this.date[0] && cur <= this.date[1]) res.push(new Date(cur).getTime())
          return res
        }, [])
      } else {
        this.dateArray = []
      }
    },
    dateArray() {
      if (this.dateArray.length !== this.animateDateArray.length || this.dateArray.length === 0) {
        this.animateDateArray = [...this.dateArray]
      } else {
        const animateDateArray = [...this.animateDateArray]
        if (this.date[0] === this.$refs.timeplayer.dates[0]) {
          gsap.to(animateDateArray, {
            duration: 1 / 100,
            ease: 'none',
            endArray: this.dateArray,
            onUpdate: () => {
              this.animateDateArray = [...animateDateArray]
            },
          })
        } else {
          gsap.to(animateDateArray, {
            duration: 1 / this.speed,
            ease: 'none',
            endArray: this.dateArray,
            onUpdate: () => {
              this.animateDateArray = [...animateDateArray]
            },
          })
        }
      }
    },
    async animateDateArray(animateDateArray, oldAnimateDateArray) {
      if (animateDateArray.length !== oldAnimateDateArray.length || animateDateArray.length === 0) {
        let layout = await this.getLayout()
        let data = await this.getData()
        let date = this.animateDateArray
        this.$refs.chart.updateChart(data, layout, date)
      } else {
        let date = this.animateDateArray
        this.$refs.chart.animateChart(date)
      }
    },
    async isDark() {
      let layout = await this.getLayout()
      let data = await this.getData()
      let date = this.animateDateArray
      this.$refs.chart.updateChart(data, layout, date)
    },
    $route() {
      if (this.$route.name === 'scatter-threejs') {
        setTimeout(() => {
          try {
            this.$refs.radioContainer.moveIndicator()
          } catch {}
        }, 1000)
      }
    },
    async countLiveMode() {
      let layout = await this.getLayout()
      let data = await this.getData()
      let date = this.animateDateArray
      this.$refs.chart.updateChart(data, layout, date)
    },
  },
  methods: {
    async getData() {
      let data = []
      let chartData = []

      for (const { dX, iX, dY, iY, dZ, iZ, dV, iV, locations } of this.datasourceExtended) {
        let ds = this.$db[dX].dates.map((d) => new Date(d).getTime())

        const locationsItemsX = locations.map((l) => l + '-' + iX)
        const locationsItemsY = locations.map((l) => l + '-' + iY)
        const locationsItemsV = locations.map((l) => l + '-' + iV)
        const locationsItemsZ = locations.map((l) => l + '-' + iZ)

        let recordsX = await this.selectAllByLocationsItems(dX, locationsItemsX, this.scale)
        let recordsY = await this.selectAllByLocationsItems(dY, locationsItemsY, this.scale)
        let recordsV = await this.selectAllByLocationsItems(dV, locationsItemsV, this.scale)
        let recordsZ = await this.selectAllByLocationsItems(dZ, locationsItemsZ, this.scale)

        // for each location
        locations.forEach((location) => {
          let name = `${location} (${iX}, ${iY}, ${iZ}, ${iV})`
          let xs = recordsX[location + '-' + iX]
          let ys = recordsY[location + '-' + iY]
          let zs = recordsZ[location + '-' + iZ]
          let vs = recordsV[location + '-' + iV]

          let x = d3.scaleLinear().domain(ds).range(xs).clamp(true).interpolate(this.interpolateFunc)
          let y = d3.scaleLinear().domain(ds).range(ys).clamp(true).interpolate(this.interpolateFunc)
          let z = d3.scaleLinear().domain(ds).range(zs).clamp(true).interpolate(this.interpolateFunc)
          let v = d3.scaleLinear().domain(ds).range(vs).clamp(true).interpolate(this.interpolateFunc)
          chartData = chartData.concat(vs)
          if (this.isDowload) data.push({ xs, ys, zs, vs, ds })
          else data.push({ name, x, y, z, v })
        })
      }
      this.chartData = chartData
      return data
    },
    async getLayout() {
      let layout = {}
      let minX = Infinity
      let minY = Infinity
      let minZ = Infinity
      let minV = Infinity
      let maxX = -Infinity
      let maxY = -Infinity
      let maxZ = -Infinity
      let maxV = -Infinity
      for (const { dX, iX, dY, iY, dZ, iZ, dV, iV, locations } of this.datasourceExtended) {
        const locationsItemsX = locations.map((l) => l + '-' + iX)
        const locationsItemsY = locations.map((l) => l + '-' + iY)
        const locationsItemsV = locations.map((l) => l + '-' + iV)
        const locationsItemsZ = locations.map((l) => l + '-' + iZ)
        let recordsX = await this.selectAllByLocationsItems(dX, locationsItemsX, this.scale)
        let recordsY = await this.selectAllByLocationsItems(dY, locationsItemsY, this.scale)
        let recordsV = await this.selectAllByLocationsItems(dV, locationsItemsV, this.scale)
        let recordsZ = await this.selectAllByLocationsItems(dZ, locationsItemsZ, this.scale)

        let recordsXValue = Object.values(recordsX)
        let recordsYValue = Object.values(recordsY)
        let recordsZValue = Object.values(recordsZ)
        let recordsVValue = Object.values(recordsV)
        //remove column date
        recordsXValue.shift()
        recordsYValue.shift()
        recordsZValue.shift()
        recordsVValue.shift()

        minX = Math.min(minY, _.min([].concat(...recordsXValue)))
        minY = Math.min(minY, _.min([].concat(...recordsYValue)))
        minV = Math.min(minY, _.min([].concat(...recordsVValue)))
        minZ = Math.min(minY, _.min([].concat(...recordsZValue)))
        maxX = Math.max(maxY, _.max([].concat(...recordsXValue)))
        maxY = Math.max(maxY, _.max([].concat(...recordsYValue)))
        maxV = Math.max(maxY, _.max([].concat(...recordsVValue)))
        maxZ = Math.max(maxY, _.max([].concat(...recordsZValue)))
        layout.xlabel = `${iX}`
        layout.ylabel = `${iY}`
        layout.zlabel = `${iZ}`
      }

      // minX have value means that all other variables also have value
      if (minX !== Infinity) {
        layout.xaxis = [minX, maxX]
        layout.yaxis = [minY, maxY]
        layout.zaxis = [minZ, maxZ]
        layout.vaxis = [minV, maxV]
      }
      layout.colors = this.colorSelect
      return layout
    },
    interpolateFunc(a, b) {
      return (t) => {
        if (a === null && b === null) return null
        if (a === null) return t === 1 ? b : null
        if (b === null) return t === 0 ? a : null
        return a * (1 - t) + b * t
      }
    },
    async downloadHtml() {
      try {
        this.isDowload = true
        let layout = await this.getLayout()
        let data = await this.getData()
        let date = this.animateDateArray
        this.isDowload = false
        let filename = this.tempProject.name + '_Scatter-thressjs'
        var request = new XMLHttpRequest()
        request.open('GET', '/templateThreejs.html', true)
        request.send(null)
        request.onreadystatechange = () => {
          if (request.readyState === 4 && request.status === 200) {
            var type = request.getResponseHeader('Content-Type')
            if (type.indexOf('text') !== 1) {
              let html = request.responseText
              html = html.replace('{{BACKGROUND}}', this.background)
              html = html.replace('{{DATA}}', JSON.stringify(data))
              html = html.replace('{{LAYOUT}}', JSON.stringify(layout))
              html = html.replace('{{DATE}}', JSON.stringify(date))
              let blob = new Blob([html], { type: 'text/plain;charset=utf-8' })
              FileSaver.saveAs(blob, `${filename}.html`)
            }
          }
        }
      } catch {}
    },
    downloadImage() {
      html2canvas(document.querySelector('canvas.threejs-chart'), { backgroundColor: null, useCORS: true }).then((canvas) => {
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = this.tempProject.name + '_Scatter-thressjs.png'
          a.click()
          URL.revokeObjectURL(url)
        })
      })
    },

    async needRedraw() {
      let layout = await this.getLayout()
      let data = await this.getData()
      let date = this.animateDateArray
      this.$refs.chart.updateChart(data, layout, date)
    },
  },
}
</script>
<style>
.scatter-three-card .layer-collapse .layer-collapse-item.open .card-header:after {
  display: none !important;
}
</style>
