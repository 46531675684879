<template>
  <div>
    <label class="toolbar-title text-primary">{{ label }}</label>
    <div class="checkbox-container">
      <label for="input">
        <component :is="icon" :style="{ color: getColor(), width: '16px', height: '16px' }" style="overflow: visible" />
      </label>
      <input id="input" type="checkbox" v-model="checked" />
      <div class="indicator" :style="{ transform: `scale(${checked ? 1 : 0})` }"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['label', 'value', 'icon'],
  data() {
    return {
      checked: this.value,
    }
  },
  watch: {
    checked(checked) {
      this.$emit('input', checked)
    },
  },
  methods: {
    getColor() {
      if (this.checked) return 'inherit'
      return '#808080'
    },
  },
}
</script>

<style scoped>
.checkbox-container {
  border: 1px solid rgba(115, 103, 240, 0.24);
  border-radius: 0.357rem;
  padding: 4px;
  display: flex;
  position: relative;
}

.checkbox-container input {
  display: none;
}

.checkbox-container label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 32px;
  margin: 0;
  cursor: pointer;
  border-radius: 0.357rem;
  margin-right: 4px;
}

.checkbox-container label:last-of-type {
  margin-right: 0;
}

.checkbox-container .indicator {
  position: absolute;
  width: 40px;
  height: 32px;
  background-color: rgba(57, 123, 255, 0.12);
  border-radius: 0.357rem;
  top: 4px;
  left: 4px;
  pointer-events: none;
  transition: transform 0.25s;
}

.checkbox-container svg {
  transition: color 0.25s;
}

/* .checkbox-container label:hover svg {
  color: #ffffff !important;
} */
</style>
