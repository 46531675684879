<template>
  <b-card no-body class="scatter-chart-card">
    <b-card-body>
      <div class="scatter-chart" ref="chartContainer" :style="{ visibility: showChart ? 'visible' : 'hidden' }">
        <canvas class="threejs-chart" ref="chart"></canvas>
        <div class="tools-bar">
          <div title="Reset axes">
            <feather-icon icon="HomeIcon" size="16" @click="resetControl" />
          </div>
        </div>
        <div class="legend-container custom-scrollbar">
          <div class="legend" :style="{ opacity: ignoreData.includes(legend.index) ? 0.5 : 1 }" @click="toggleItem(legend.index)" v-for="legend in legendData" :key="legend.name">
            <div class="item-shape" :style="{ background: legend.color }"></div>
            <div class="item-name" :style="{ fontFamily: chartFontFamily ? chartFontFamily : 'inherit', fontSize: chartTextSize ? `${chartTextSize}px` : 'inherit', color: chartTextColor ? chartTextColor : 'inherit' }" :title="legend.name">{{ legend.name }}</div>
          </div>
        </div>
      </div>
      <div class="scatter-chart empty-chart" :style="{ visibility: showChart ? 'hidden' : 'visible' }">
        <EmptyChart :description="$t('tips_scatter')" />
      </div>
      <chart-year-text ref="refChartYearText" v-if="showChart && $parent.annotations" :text="$parent.annotations"></chart-year-text>
    </b-card-body>
  </b-card>
</template>

<script>
import * as d3 from 'd3'
import * as THREE from 'three'
import EmptyChart from '../../common/EmptyChart.vue'
import ChartYearText from '../../common/ChartYearText.vue'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { CHART_COLORS } from '@/constants/colors'
import { ThemeConfig } from '@/mixins/ThemeMixin'

const MARGIN = 40
const MIN_SIZE = 5
const MAX_SIZE = 30
const COLORS = CHART_COLORS.PLOTLY_HEX
const COLORS_LEGEND = CHART_COLORS.PLOTLY

export default {
  props: ['nameComponent'],
  components: { EmptyChart, ChartYearText },
  mixins: [ThemeConfig],
  destroyed() {
    window.cancelAnimationFrame(this.tickId)
  },
  mounted() {
    if (this.isDark) this.themeColor = '#ffffff'
    else this.themeColor = '#000000'
    try {
      const color = this.isDark ? '#d0d2d6' : '#444'
      this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { chartTextColor: color })
    } catch {}
  },
  data() {
    return {
      scene: null,
      chartGroup: null,
      axisGroup: null,
      camera: null,
      control: null,
      sizes: null,
      showChart: false,
      themeColor: '#000000',
      legendData: [],
      ignoreData: [],
      xScale: null,
      yScale: null,
      zScale: null,
    }
  },
  computed: {
    project() {
      return this.$store.state.ecoplot.project
    },
    sizeWidth() {
      return this.$store.state.tabs['scatter_three'].sizeWidth
    },
    chartFontFamily() {
      return this.$store.state.tabs[this.nameComponent].chartFontFamily
    },
    chartTextSize() {
      return this.$store.state.tabs[this.nameComponent].chartTextSize
    },
    chartTextColor() {
      return this.$store.state.tabs[this.nameComponent].chartTextColor
    },
    chartFont() {
      let font = {}
      try {
        if (this.chartFontFamily) {
          font.family = this.chartFontFamily
        }
        if (this.chartTextSize) {
          font.size = this.chartTextSize
        }
        if (this.chartTextColor) {
          font.color = this.chartTextColor
        }
      } catch {}
      return font
    },
  },
  watch: {
    isDark() {
      if (this.isDark) {
        this.themeColor = '#ffffff'
      } else {
        this.themeColor = '#000000'
      }
      try {
        const color = this.isDark ? '#d0d2d6' : '#444'
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { chartTextColor: color })
      } catch {}
    },
    project() {
      this.resetControl()
    },
  },
  methods: {
    createChart(data, layout, date) {
      // size
      this.sizes = { width: this.$refs.chartContainer.offsetWidth, height: this.$refs.chartContainer.offsetHeight }

      // scene
      this.scene = new THREE.Scene()

      // chart
      this.chartGroup = new THREE.Group()
      this.scene.add(this.chartGroup)

      // axis
      this.axisGroup = new THREE.Group()
      this.scene.add(this.axisGroup)

      // camera
      this.camera = new THREE.OrthographicCamera()
      this.camera.position.z = 1000
      this.updateCamera()
      this.scene.add(this.camera)

      // control
      this.control = new OrbitControls(this.camera, this.$refs.chart)

      // helper
      // const axesHelper = new THREE.AxesHelper(100)
      // this.scene.add(axesHelper)

      // renderer
      const renderer = new THREE.WebGLRenderer({ canvas: this.$refs.chart, alpha: true, antialias: true, preserveDrawingBuffer: true })
      renderer.setSize(this.sizes.width, this.sizes.height)
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

      const tick = () => {
        this.control.update()

        renderer.render(this.scene, this.camera)

        this.tickId = window.requestAnimationFrame(tick)
      }
      tick()

      // listen to div resize => chart resize
      new ResizeObserver(() => {
        this.sizes = { width: this.$refs.chartContainer.offsetWidth, height: this.$refs.chartContainer.offsetHeight }

        this.updateCamera()
        this.camera.updateProjectionMatrix()

        if (this.data && this.layout && this.date) {
          this.updateChart(this.data, this.layout, this.date)
        }

        renderer.setSize(this.sizes.width, this.sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
      }).observe(this.$refs.chartContainer)

      this.updateChart(data, layout, date)
    },
    updateChart(data, layout, date) {
      try {
        this.showChart = Boolean(data.length)
        this.data = data
        this.layout = layout

        let maxSize = MAX_SIZE
        let minSize = MIN_SIZE
        if (this.sizeWidth.default == true) {
          minSize = this.sizeWidth.range[0]
          maxSize = this.sizeWidth.range[1]
        }

        if (layout.xaxis) {
          this.xTicks = d3.scaleLinear().domain(layout.xaxis).nice(6).ticks(6)
          this.yTicks = d3.scaleLinear().domain(layout.yaxis).nice(6).ticks(6)
          this.zTicks = d3.scaleLinear().domain(layout.zaxis).nice(6).ticks(6)

          let axisLength = Math.min(this.sizes.width, this.sizes.height)
          this.xScale = d3
            .scaleLinear()
            .domain([this.xTicks[0], this.xTicks[this.xTicks.length - 1]])
            .range([axisLength / -2 + MARGIN, axisLength / 2 - MARGIN])
          this.yScale = d3
            .scaleLinear()
            .domain([this.yTicks[0], this.yTicks[this.yTicks.length - 1]])
            .range([axisLength / -2 + MARGIN, axisLength / 2 - MARGIN])
          this.zScale = d3
            .scaleLinear()
            .domain([this.zTicks[0], this.zTicks[this.zTicks.length - 1]])
            .range([axisLength / -2 + MARGIN, axisLength / 2 - MARGIN])
          this.vScale = (value) => ((value - layout.vaxis[0]) / (layout.vaxis[1] - layout.vaxis[0])) * (maxSize - minSize) + minSize

          /** REDRAW AXIS */
          this.drawAxis(layout)
        }

        /** REDRAW POINT */
        this.drawPoint(data, date, layout)

        /** ANIMATE POINT */
        this.animateChart(date)
      } catch {}
    },
    drawAxis(layout) {
      this.axisGroup.clear()

      // x axis = undefined means all axis = undefined
      if (!layout.xaxis) return

      // X grid line
      let xOrigin = this.xTicks[0]
      const xyGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, this.yScale(this.yTicks[0]), this.zScale(this.zTicks[0])), new THREE.Vector3(0, this.yScale(this.yTicks[this.yTicks.length - 1]), this.zScale(this.zTicks[0]))])
      const xzGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, this.yScale(this.yTicks[0]), this.zScale(this.zTicks[0])), new THREE.Vector3(0, this.yScale(this.yTicks[0]), this.zScale(this.zTicks[this.zTicks.length - 1]))])
      this.xTicks.forEach((tick, index) => {
        if (tick === xOrigin) return

        let x = this.xScale(tick)

        const xyMaterial = new THREE.LineBasicMaterial({ color: this.themeColor, transparent: true, opacity: 0.25 })
        const xyLine = new THREE.Line(xyGeometry, xyMaterial)
        xyLine.position.x = x
        this.axisGroup.add(xyLine)

        const xzMaterial = new THREE.LineBasicMaterial({ color: this.themeColor, transparent: true, opacity: 0.25 })
        const xzLine = new THREE.Line(xzGeometry, xzMaterial)
        xzLine.position.x = x
        this.axisGroup.add(xzLine)
      })

      // Y grid line
      let yOrigin = this.yTicks[0]
      const yxGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(this.xScale(this.xTicks[0]), 0, this.zScale(this.zTicks[0])), new THREE.Vector3(this.xScale(this.xTicks[this.xTicks.length - 1]), 0, this.zScale(this.zTicks[0]))])
      const yzGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(this.xScale(this.xTicks[0]), 0, this.zScale(this.zTicks[0])), new THREE.Vector3(this.xScale(this.xTicks[0]), 0, this.zScale(this.zTicks[this.zTicks.length - 1]))])
      this.yTicks.forEach((tick, index) => {
        if (tick === yOrigin) return

        let y = this.yScale(tick)

        const yxMaterial = new THREE.LineBasicMaterial({ color: this.themeColor, transparent: true, opacity: 0.25 })
        const yxLine = new THREE.Line(yxGeometry, yxMaterial)
        yxLine.position.y = y
        this.axisGroup.add(yxLine)

        const yzMaterial = new THREE.LineBasicMaterial({ color: this.themeColor, transparent: true, opacity: 0.25 })
        const yzLine = new THREE.Line(yzGeometry, yzMaterial)
        yzLine.position.y = y
        this.axisGroup.add(yzLine)
      })

      // Z grid line
      let zOrigin = this.zTicks[0]
      const zxGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(this.xScale(this.xTicks[0]), this.yScale(this.yTicks[0]), 0), new THREE.Vector3(this.xScale(this.xTicks[this.xTicks.length - 1]), this.yScale(this.yTicks[0]), 0)])
      const zyGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(this.xScale(this.xTicks[0]), this.yScale(this.yTicks[0]), 0), new THREE.Vector3(this.xScale(this.xTicks[0]), this.yScale(this.yTicks[this.yTicks.length - 1]), 0)])
      this.zTicks.forEach((tick, index) => {
        if (tick === zOrigin) return

        let z = this.zScale(tick)

        const zxMaterial = new THREE.LineBasicMaterial({ color: this.themeColor, transparent: true, opacity: 0.25 })
        const zxLine = new THREE.Line(zxGeometry, zxMaterial)
        zxLine.position.z = z
        this.axisGroup.add(zxLine)

        const zyMaterial = new THREE.LineBasicMaterial({ color: this.themeColor, transparent: true, opacity: 0.25 })
        const zyLine = new THREE.Line(zyGeometry, zyMaterial)
        zyLine.position.z = z
        this.axisGroup.add(zyLine)
      })

      // X zero line
      {
        const zxMaterial = new THREE.LineBasicMaterial({ color: this.themeColor })
        const zxLine = new THREE.Line(zxGeometry, zxMaterial)
        zxLine.position.z = this.zScale(zOrigin)
        this.axisGroup.add(zxLine)
      }

      // Y zero line
      {
        const xyMaterial = new THREE.LineBasicMaterial({ color: this.themeColor })
        const xyLine = new THREE.Line(xyGeometry, xyMaterial)
        xyLine.position.x = this.xScale(xOrigin)
        this.axisGroup.add(xyLine)
      }

      // Z zero line
      {
        const yzMaterial = new THREE.LineBasicMaterial({ color: this.themeColor })
        const yzLine = new THREE.Line(yzGeometry, yzMaterial)
        yzLine.position.y = this.yScale(yOrigin)
        this.axisGroup.add(yzLine)
      }

      // X label
      this.xTicks.forEach((tick, index) => {
        let { sprite, width, height } = this.makeTextSprite(tick)
        sprite.position.x = this.xScale(tick)
        sprite.position.y = this.yScale(this.yTicks[0]) - (height / 2 + 7)
        sprite.position.z = this.zScale(this.zTicks[0]) - (width / 2 + 7)
        this.axisGroup.add(sprite)
        if (index === Math.floor(this.xTicks.length / 2)) {
          let { sprite, width, height } = this.makeTextSprite(layout.xlabel, '#FF0000', 20)
          sprite.position.x = this.xScale((this.xTicks[this.xTicks.length - 1] + this.xTicks[0]) / 2)
          sprite.position.y = this.yScale(this.yTicks[0]) - (height / 2 + 7)
          sprite.position.z = this.zScale(this.zTicks[0]) - (width / 2 + 7)
          this.axisGroup.add(sprite)
        }
      })

      // Y label
      this.yTicks.forEach((tick, index) => {
        let { sprite, width, height } = this.makeTextSprite(tick)
        sprite.position.x = this.xScale(this.xTicks[0]) - (width / 2 + 7)
        sprite.position.y = this.yScale(tick)
        sprite.position.z = this.zScale(this.zTicks[0]) - (height / 2 + 7)
        this.axisGroup.add(sprite)
        if (index === Math.floor(this.yTicks.length / 2)) {
          let { sprite, width, height } = this.makeTextSprite(layout.ylabel, '#FF0000', 20)
          sprite.position.x = this.xScale(this.xTicks[0]) - (width / 2 + 7)
          sprite.position.y = this.yScale((this.yTicks[this.yTicks.length - 1] + this.yTicks[0]) / 2)
          sprite.position.z = this.zScale(this.zTicks[0]) - (height / 2 + 7)
          this.axisGroup.add(sprite)
        }
      })

      // Z label
      this.zTicks.forEach((tick, index) => {
        let { sprite, width, height } = this.makeTextSprite(tick)
        sprite.position.x = this.xScale(this.xTicks[0]) - (width / 2 + 7)
        sprite.position.y = this.yScale(this.yTicks[0]) - (height / 2 + 7)
        sprite.position.z = this.zScale(tick)
        this.axisGroup.add(sprite)

        if (index === Math.floor(this.zTicks.length / 2)) {
          let { sprite, width, height } = this.makeTextSprite(layout.zlabel, '#FF0000', 20)
          sprite.position.x = this.xScale(this.xTicks[0]) - (width / 2 + 7)
          sprite.position.y = this.yScale(this.yTicks[0]) - (height / 2 + 7)
          sprite.position.z = this.zScale((this.zTicks[this.zTicks.length - 1] + this.zTicks[0]) / 2)
          this.axisGroup.add(sprite)
        }
      })
    },
    drawPoint(data, date, layout) {
      if (this.points) {
        this.geometry.dispose()
        this.material.dispose()
        this.chartGroup.remove(this.points)
      }

      this.geometry = new THREE.BufferGeometry()
      const pointCount = data.length
      const positions = []
      const colors = []
      const aSizes = []
      const ignoreDraw = []
      this.legendData = []
      for (let i = 0; i < data.length; i++) {
        this.legendData.push({
          index: i,
          name: data[i].name,
          color: layout.colors[i % layout.colors.length],
        })

        for (let j = 0; j < date.length; j++) {
          positions.push(0, 0, 0)
          let color = new THREE.Color(layout.colors[i % layout.colors.length])
          if (this.ignoreData.includes(i)) ignoreDraw.push(1)
          else ignoreDraw.push(0)
          colors.push(color.r, color.g, color.b)
          aSizes.push(0)
        }
      }
      this.geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(positions), 3))
      this.geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array(colors), 3))
      this.geometry.setAttribute('aSize', new THREE.BufferAttribute(new Float32Array(aSizes), 1))
      this.geometry.setAttribute('aDraw', new THREE.BufferAttribute(new Float32Array(ignoreDraw), 1))
      this.material = new THREE.ShaderMaterial({
        depthWrite: false,
        blending: THREE.AdditiveBlending,
        vertexColors: true,
        vertexShader: `
          attribute float aSize;
          attribute float aDraw;

          varying vec3 vColor;
          varying float vSize;
          varying float vDraw;

          void main()
          {
            vec4 modelPosition = modelMatrix * vec4(position, 1.0);
            vec4 viewPosition = viewMatrix * modelPosition;
            vec4 projectedPosition = projectionMatrix * viewPosition;
            gl_Position = projectedPosition;

            gl_PointSize = aSize;

            vColor = color;
            vSize = aSize;
            vDraw = aDraw;
          }
        `,
        fragmentShader: `
          varying vec3 vColor;
          varying float vSize;
          varying float vDraw;

          void main()
          {
            float strength = distance(gl_PointCoord, vec2(0.5));
            strength = step(0.5, strength);
            strength = 1.0 - strength;
            if(vSize == 0.0 || vDraw == 1.0) strength = 0.0;

            gl_FragColor = vec4(vColor, strength);
          }
        `,
      })

      this.points = new THREE.Points(this.geometry, this.material)
      this.chartGroup.add(this.points)
    },
    animateChart(date) {
      try {
        this.date = date
        let tmpIndex = null
        let tmpX = null
        let tmpY = null
        let tmpZ = null
        let tmpV = null
        for (let i = 0; i < this.data.length; i++) {
          for (let j = 0; j < date.length; j++) {
            tmpIndex = i * date.length + j
            tmpX = this.data[i].x(date[j])
            tmpY = this.data[i].y(date[j])
            tmpZ = this.data[i].z(date[j])
            tmpV = this.data[i].v(date[j])
            if (tmpX === null || tmpY === null || tmpZ === null || tmpV === null) {
              this.geometry.attributes.aSize.array[tmpIndex] = 0
            } else {
              this.geometry.attributes.position.array[tmpIndex * 3 + 0] = this.xScale(tmpX)
              this.geometry.attributes.position.array[tmpIndex * 3 + 1] = this.yScale(tmpY)
              this.geometry.attributes.position.array[tmpIndex * 3 + 2] = this.zScale(tmpZ)
              this.geometry.attributes.aSize.array[tmpIndex] = this.vScale(tmpV)
            }
          }
        }
        this.geometry.attributes.position.needsUpdate = true
        this.geometry.attributes.aSize.needsUpdate = true
      } catch {}
    },
    updateCamera() {
      this.camera.top = this.sizes.height / 2
      this.camera.bottom = this.sizes.height / -2
      this.camera.left = this.sizes.width / -2
      this.camera.right = this.sizes.width / 2
    },
    makeTextSprite(message, color = null, font = 14) {
      let canvas = document.createElement('canvas')
      let context = canvas.getContext('2d')
      context.font = `${font * 4}px Arial`
      let width = context.measureText(message).width / 5
      let height = font
      context.fillStyle = color ? color : this.themeColor
      context.textBaseline = 'middle'
      context.textAlign = 'center'
      context.fillText(message, 150, 75)

      let texture = new THREE.Texture(canvas)
      texture.needsUpdate = true
      texture.minFilter = THREE.NearestFilter
      let spriteMaterial = new THREE.SpriteMaterial({ map: texture })
      let sprite = new THREE.Sprite(spriteMaterial)
      sprite.scale.set(300 / 5, 150 / 5, 1)
      return { sprite, width, height }
    },
    resetControl() {
      this.control.reset()
    },
    toggleItem(idx) {
      let existIdx = this.ignoreData.findIndex((i) => i === idx)
      if (existIdx === -1) this.ignoreData.push(idx)
      else this.ignoreData.splice(existIdx, 1)
      this.$parent.needRedraw()
    },
  },
}
</script>

<style scoped>
.item-name {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-shape {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border-radius: 50%;
}
.legend-container {
  height: calc(100vh - 30px - 90.3px - 90px - 42px - 36px - 50px);
  overflow-y: auto;
  position: absolute;
  top: 25px;
  right: 0;
}
.legend {
  user-select: none;
  cursor: pointer;
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scatter-chart {
  position: relative;
  height: calc(100vh - 30px - 90.3px - 90px - 22px - 2rem - 36px);
  overflow: hidden;
}

.scatter-chart .tools-bar {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row-reverse;
  opacity: 0;
  transition: opacity 0.25s;
}

.scatter-chart:hover .tools-bar {
  opacity: 1;
}

.scatter-chart .tools-bar > div {
  margin-left: 5px;
}

.scatter-chart .tools-bar svg {
  cursor: pointer;
  display: block;
}
.dark-layout .scatter-chart .tools-bar svg {
  color: #fff;
}
.scatter-chart .tools-bar svg {
  color: #000;
}

.scatter-chart .scatter-tooltip {
  position: absolute;
  border: 1px solid #fff;
  background-color: #000;
  color: #fff;
  padding: 5px;
  transform: translate(-50%, 50%);
  white-space: nowrap;
  pointer-events: none;
  user-select: none;
  transition: all 0.25s;
}

.scatter-chart.empty-chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.dark-layout .legend {
  color: #b4b7bd;
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-leave,
.fade-enter-to {
  opacity: 1;
}
</style>
